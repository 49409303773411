<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
              >組織資料設定</b-breadcrumb-item
            >
            <b-breadcrumb-item active>通知樣板管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card :title="`${organizationName}通知樣板管理`">
      <b-card-text>
        <b-overlay :show="showLoading" rounded="sm">
          <b-table
            striped
            hover
            responsive
            :items="templates"
            :fields="fields"
          >
            <template #cell(actions)="data">
              <b-button-group>
                <b-button title="編輯" :to="{name: 'TemplateEdit', params: {template_id: data.item.id}}">
                  <BIconPencil />
                </b-button>
                <b-button class="ml-2" title="預覽 (TODO)" :to="{name: 'TemplateShow', params: {template_id: data.item.id}}">
                  <BIconEye />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-overlay>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            align="center"
            @change="getTemplates"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import templateApi from "../../../apis/template"
import {BIconPencil, BIconEye} from "bootstrap-vue";

export default {
  components: {
    BIconPencil,
    BIconEye,
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      showLoadingUpload: false,
      templates: [],
      organizationName: this.$store.state.general.organization.name,
      fields: [
        {
          key: 'key',
          label: 'Key',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'value',
          label: 'Value',
          formatter: x => x ? x.substring(0, 20) + "..." : ""
        },
        {
          key: 'actions',
          label: '操作'
        }
      ]
    }
  },
  computed: {
    ...mapState('general', {
      organization: state => state.organization
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getTemplates()
    },
    async getTemplates(page) {
      this.showLoading = true
      try {
        const { data } = await templateApi.list({
          page: page || this.currentPage,
          org_code: this.organization.id,
          merchant_id: this.$route.params.merchant_id,
        })
        this.templates = data.data
        this.totalRows = data.meta.total
      } catch (error) {
        console.error(error)
        if (error.status !== 401) {
          this.$swal.fire({
            title: '錯誤',
            type: 'error',
            text: '取得組織參數錯誤'
          })
        }
      }
      this.showLoading = false
    },
  }
}
</script>
